import React, { Component } from "react";
import ContactItem from "./ContactItem/ContactItem";
import styles from "./Contact.module.css";
import contactImage from "../../../assets/contacts.png";

class Contact extends Component {
  state = {
    contacts: [
      {
        type: "phone",
        link: "tel:910632828",
      },
      {
        type: "facebook",
        link: "https://www.facebook.com/understudiocom-629087820444968/about/?ref=page_internal",
      },
      {
        type: "location",
        link: "https://www.google.com/maps/place/Understudio+-+Arquitectura+-+São+Miguel+-+Açores/@37.7400394,-25.6658016,15z/data=!4m5!3m4!1s0x0:0xfc8dde949d6b9342!8m2!3d37.7400394!4d-25.6658016",
      },
      {
        type: "instagram",
        link: "https://www.instagram.com/understudio69/",
      },
      {
        type: "e-mail",
        link: "mailto:understudio@understudio.com",
      },
    ],
  };
  render() {
    return (
      <div className={styles.Contact}>
        <div>
          <img className={styles.Image} src={contactImage} alt="Contacts" />
        </div>
        <div className={styles.ContactList}>
          <ul>
            {this.state.contacts.map((contact) => (
              <ContactItem key={contact.link} label={contact.type} link={contact.link} />
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Contact;
