import React from "react";
import { projectAuth } from "../../firebase/config";
// import { useAuthState } from "react-firebase-hooks/auth";
import LogOut from "../../components/LogOut/LogOut";
import { NavLink } from "react-router-dom";

// FONT AWESOME IMPORTS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNewspaper,
  faEnvelope,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";

import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";

const User = () => {
  // const [user] = useAuthState(projectAuth);
  const { displayName } = projectAuth.currentUser;
  return (
    <Box mb={3}>
      <h2
        style={{
          color: "#004d40",
          display: "flex",
          justifyContent: "center",
        }}
      >
        Olá {displayName}
      </h2>
      <Grid
        container
        spacing={1}
        style={{ display: "flex", justifyContent: "center" }}
        mt={3}
        mb={3}
      >
        <Grid item xs={8} sm={6} style={{ height: "200px" }}>
          <NavLink to={"/new-project"} exact>
            <Button
              variant="contained"
              type="button"
              style={{
                backgroundColor: "var(--nav-primary)",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                justifyContent: "space-around",
                fontSize: "1.2em",
              }}
            >
              <FontAwesomeIcon
                icon={faBuilding}
                style={{ width: "1em", fontSize: "4em" }}
              />
              Novo Projeto
            </Button>
          </NavLink>
        </Grid>
        <Grid item xs={8} sm={6} style={{ height: "200px" }}>
          <NavLink to={"/delete-project"} exact>
            <Button
              variant="contained"
              type="button"
              style={{
                backgroundColor: "#004d40",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                justifyContent: "space-around",
                fontSize: "1.2em",
              }}
            >
              <FontAwesomeIcon
                icon={faBuilding}
                style={{ width: "1em", fontSize: "4em" }}
              />
              Eliminar Projeto
            </Button>
          </NavLink>
        </Grid>
        <Grid item xs={8} sm={6} style={{ height: "200px" }}>
          <NavLink to={"/new-post"} exact>
            <Button
              variant="contained"
              type="button"
              style={{
                backgroundColor: "var(--nav-primary)",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                justifyContent: "space-around",
                fontSize: "1.2em",
              }}
            >
              <FontAwesomeIcon
                icon={faNewspaper}
                style={{ width: "1em", fontSize: "4em" }}
              />
              Nova Publicação
            </Button>
          </NavLink>
        </Grid>
        <Grid item xs={8} sm={6} style={{ height: "200px" }}>
          <NavLink to={"/delete-post"} exact>
            <Button
              variant="contained"
              type="button"
              style={{
                backgroundColor: "#004d40",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                justifyContent: "space-around",
                fontSize: "1.2em",
              }}
            >
              <FontAwesomeIcon
                icon={faNewspaper}
                style={{ width: "1em", fontSize: "4em" }}
              />
              Eliminar Publicação
            </Button>
          </NavLink>
        </Grid>
        <Grid item xs={8} sm={6} style={{ height: "200px" }}>
          <NavLink to={"/new-cover"} exact>
            <Button
              variant="contained"
              type="button"
              style={{
                backgroundColor: "var(--nav-primary)",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                justifyContent: "space-around",
                fontSize: "1.2em",
              }}
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{ width: "1em", fontSize: "4em" }}
              />
              Novo Postal
            </Button>
          </NavLink>
        </Grid>
        <Grid item xs={8} sm={6} style={{ height: "200px" }}>
          <NavLink to={"/delete-cover"} exact>
            <Button
              variant="contained"
              type="button"
              style={{
                backgroundColor: "#004d40",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                justifyContent: "space-around",
                fontSize: "1.2em",
              }}
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{ width: "1em", fontSize: "4em" }}
              />
              Eliminar Postal
            </Button>
          </NavLink>
        </Grid>
      </Grid>
      <LogOut />
    </Box>
  );
};

export default User;
