import { useState, useEffect } from "react";
import { projectFirestore } from "../../firebase/config";

const GetCoverData = (id) => {
  const [cover, setCover] = useState([]);
  useEffect(() => {
    const docRef = projectFirestore.collection("Gallery").doc(id);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          setCover(doc.data());
        } else {
          console.log("No such document");
        }
      })
      .catch((err) => {
        console.log("Error getting project: ", err);
      });
  }, [id]);
  return cover;
};

export default GetCoverData;
