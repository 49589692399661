import React from "react";

import { projectAuth } from "../../firebase/config";
import { useAuthState } from "react-firebase-hooks/auth";
import LogIn from "../LogIn/LogIn";
import User from "../User/User";

import Layout from "../../hoc/Layout/Layout";

const Authentication = () => {
  const [user] = useAuthState(projectAuth);
  
  return (
    <div>
      <Layout>{user ? <User /> : <LogIn />}</Layout>
    </div>
  );
};

export default Authentication;
