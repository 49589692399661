import React from "react";

import styles from "./LogIn.module.css";

import GoogleLogo from "../../assets/GoogleLogo.png";
import { Button } from "@mui/material";
import { Box } from "@mui/system";

import { projectAuth } from "../../firebase/config";
import { firebase } from "@firebase/app";
import "firebase/auth";

const LogIn = () => {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    projectAuth.signInWithPopup(provider);
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        boxShadow:
          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
        borderRadius: "5px",
        padding: "100px 30px",
        alignItems: "center",
      }}
    >
      <h2
        style={{
          color: "black",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        Log in with
      </h2>
      <Button
        style={{
          display: "flex",
          padding: "20px 15px",
          flexDirection: "column",
        }}
        variant="link"
        size="large"
        onClick={signInWithGoogle}
      >
        <img src={GoogleLogo} alt="Google Logo" className={styles.google} />
      </Button>
    </Box>
  );
};

export default LogIn;
