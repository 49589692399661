import React, { useState } from "react";
import { imageFetch } from "./ImageFetch";
import { Link } from "react-router-dom";

import styles from "./Slideshow.module.css";

const Slideshow = ({ covers: props }) => {
  let [current, setCurrent] = useState(0);
  const length = props.length;

  const prevent = (e) => {
    e.preventDefault();
  };

  const nextCoverHandler = () => {
    setCurrent(current === length - 1 ? length - 1 : current + 1);
  };

  const prevCoverHandler = () => {
    setCurrent(current === 0 ? 0 : current - 1);
  };

  if (!Array.isArray(props) || props.length <= 0) {
    return null;
  }
  return (
    <div className={styles.Slideshow}>
      <div className={styles.Buttons}>
        <p onClick={prevCoverHandler} className={styles.Left}>
          {current === 0 ? (
            <button
              onClick={prevent}
              style={{ justifyContent: "flex-start", paddingLeft: "7vh" }}
            >
              <div className={styles.RotateLeft}>+</div>
            </button>
          ) : (
            <button
              style={{ justifyContent: "flex-start", paddingLeft: "7vh" }}
            >
              +
            </button>
          )}
        </p>

        <Link to="/projects" className={styles.Center}>
          {window.innerWidth < 900 ? (
            <button><p style={{ position: "fixed", bottom: "50px"}}className={styles.Welcome}>Welcome</p></button>
          ) : (
            <button></button>
          )}
        </Link>
        <p onClick={nextCoverHandler} className={styles.Right}>
          {current === length - 1 ? (
            <button
              onClick={prevent}
              style={{ justifyContent: "flex-end", paddingRight: "7vh" }}
            >
              <div className={styles.RotateRight}>+</div>
            </button>
          ) : (
            <button style={{ justifyContent: "flex-end", paddingRight: "7vh" }}>
              +
            </button>
          )}
        </p>
      </div>
      {imageFetch.map((cover, index) => {
        return (
          <div className={index === current ? "Active" : "Cover"} key={index}>
            {index === current && (
              <img src={cover.image} alt="wow" className={styles.Image} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Slideshow;
