import React from "react";

import NavigationItem from "./NavigationItem/NavigationItem";
import styles from "./NavigationItems.module.css";
const NavigationItems = () => {
  const projectDropdown = [
    { id: 0, value: "architecture" },
    { id: 1, value: "land" },
    { id: 2, value: "design" },
    { id: 3, value: "list" },
  ];
  // const officeDropdown = [
  //   { id: 5, value: "team" },
  // { id: 4, value: "contact" },
  // ];

  return (
    <div>
      <ul className={styles.NavigationItems}>
        <NavigationItem
          link={`/projects`}
          name="projects"
          dropList={projectDropdown}
        />
        <NavigationItem link={`/news`} name="news" exact />
        <NavigationItem
          link={`/office`}
          name="office"
          // dropList={officeDropdown}
          style={{ marginLeft: "140px" }}
        />
        <NavigationItem
          link={`/team`}
          name="team"
          style={{ marginLeft: "140px" }}
        />
      </ul>
    </div>
  );
};

export default NavigationItems;
