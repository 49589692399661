import React, { Component } from "react";
import Auxiliary from "../../hoc/Auxiliary";

import Slideshow from "../../components/Slideshow/Slideshow";
import { imageFetch } from "../../components/Slideshow/ImageFetch";
class Home extends Component {
  render() {
    return (
      <Auxiliary>
        <Slideshow covers={imageFetch} />
      </Auxiliary>
    );
  }
}

export default Home;
