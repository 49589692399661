import React from "react";
import { NavLink } from "react-router-dom";

import { projectAuth } from "../../firebase/config";
import "firebase/auth";
import { Button } from "@mui/material";
const LogOut = () => {
  return (
    projectAuth.currentUser && (
      <NavLink to="/user" exact>
        <Button
          style={{ backgroundColor: "var(--nav-primary)", width: "100%", height:"70px", fontSize: "1.1em"}}
          variant="contained"
          onClick={() => projectAuth.signOut()}
        >
          Log Out
        </Button>
      </NavLink>
    )
  );
};

export default LogOut;
