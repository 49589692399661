import React, { Component } from "react";

import Layout from "../../../hoc/Layout/Layout";
import PortfolioProjects from "../../../components/Portfolio/Portfolio";

import Spinner from "../../../components/UI/Spinner/Spinner";
class List extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    demoAsync().then(() => {
      this.setState({ loading: false });
    });
  }
  render() {
    const { loading } = this.state;

    if (loading) {
      return (
        <Layout>
          <Spinner />
        </Layout>
      );
    }
    return (
      <Layout>
        <PortfolioProjects type="list" />
      </Layout>
    );
  }
}
function demoAsync() {
  return new Promise((resolve) => setTimeout(() => resolve(), 2500));
}

export default List;
