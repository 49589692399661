import React, { Component } from "react";

import Layout from "../../hoc/Layout/Layout";
import OfficeInformation from "../../components/OfficeInformation/OfficeInformation";

import Spinner from "../../components/UI/Spinner/Spinner";
class Office extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    demoAsync().then(() => {
      this.setState({ loading: false });
    });
  }
  render() {
    const { loading } = this.state;

    if (loading) {
      return (
        <Layout>
          <Spinner />
        </Layout>
      );
    }
    return (
      <Layout>
        <OfficeInformation type="contact" />
      </Layout>
    );
  }
}
function demoAsync() {
  return new Promise((resolve) => setTimeout(() => resolve(), 2500));
}

export default Office;
