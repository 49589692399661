import React from "react";
import styles from "../Slideshow.module.css";
import GetCoverData from "../../hooks/GetCoverData";
import { Link, useParams } from "react-router-dom";

const Cover = () => {
  const { id } = useParams();
  let cover = GetCoverData(id);

  if (cover) {
    return (
      <div>
        <Link to="/projects">
          <div className={styles.WelcomeContainer}>
            <button className={styles.Welcome}>Welcome</button>
          </div>
        </Link>
        <img src={cover.url} alt={cover.name} className={styles.Image} />
      </div>
    );
  } else {
    return <h3>No images for cover</h3>;
  }
};

export default Cover;
