import React, { Component } from "react";
import {
  projectStorage,
  projectFirestore,
  timestamp,
} from "../../../../firebase/config";
import Layout from "../../../../hoc/Layout/Layout";

import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { Link } from "react-router-dom";

class NewProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      files: [],
      fileInfo: [],
      url: [],
      type: ["list"],
      description: "",
      location: "",
      geoRef: "",
      projectDate: "",
      realizationDate: "",
      createdAt: timestamp(),
      formErrors: {},
    };

    this.initialState = this.state;
  }

  handleFormValidation = () => {
    const {
      title,
      description,
      location,
      geoRef,
      projectDate,
      realizationDate,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    // Title
    if (!title) {
      formIsValid = false;
      formErrors["titleError"] = "Tem que inserir um título";
    }

    // Description
    if (!description) {
      formIsValid = false;
      formErrors["descriptionError"] = "Tem que inserir uma descrição";
    }

    // Location
    if (!location) {
      formIsValid = false;
      formErrors["locationError"] = "Tem que inserir uma localização";
    }

    // GeoRef
    if (!geoRef) {
      formIsValid = false;
      formErrors["geoRefError"] = "Tem que inserir um URL com a geo-referência";
    }
    // Project Date
    if (!projectDate) {
      formIsValid = false;
      formErrors["projectDateError"] = "Tem que inserir uma data";
    }

    // Realization Date
    if (!realizationDate) {
      formIsValid = false;
      formErrors["realizationDateError"] = "Tem que inserir uma data";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  // onChange para todos os inputs de texto e data
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  //onChange para ficheiros
  handleChangeFile = (e) => {
    let selected = e.target;
    let files = selected.files;
    // let file;
    let storeFiles = [];
    for (let i = 0; i < files.length; i++) {
      // file = files[i];
      storeFiles.push(files[i]);
      this.setState((prevState) => ({
        files: [...prevState.files, storeFiles[i].name],
      }));
      this.setState((prevState) => ({
        fileInfo: [...prevState.fileInfo, storeFiles[i]],
      }));
    }
  };

  // onClick para ficheiros
  handleFileUpdate = () => {
    if (this.state.title && this.state.fileInfo) {
      const storageRef = projectStorage.ref();
      for (let i = 0; i < this.state.fileInfo.length; i++) {
        let fileRef = storageRef.child(
          `Projects/${this.state.title}/${this.state.files[i]}`
        );
        let uploadTask = fileRef.put(this.state.fileInfo[i]);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
          },
          (error) => {
            console.log(error);
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.setState((prevState) => ({
                url: [...prevState.url, downloadURL],
              }));
            });
          }
        );
      }
    }
  };

  // onChange para as checkboxes
  handleOnChangeCheckbox = (e) => {
    let newArr = [...this.state.type, e.target.id];
    if (this.state.type.includes(e.target.id)) {
      newArr = newArr.filter((typ) => typ !== e.target.id);
    }
    this.setState({
      type: newArr,
    });
  };

  // submit do projeto
  handleSubmit = (e) => {
    const values = {
      title: this.state.title,
      files: this.state.files,
      url: this.state.url,
      type: this.state.type,
      description: this.state.description,
      location: this.state.location,
      geoRef: this.state.geoRef,
      projectDate: this.state.projectDate,
      realizationDate: this.state.realizationDate,
      createdAt: this.state.createdAt,
    };
    e.preventDefault();
    if (this.handleFormValidation()) {
      if (this.state.files) {
        projectFirestore
          .collection("Projects")
          .add(values)
          .then(() => {
            alert("Successfully created a new project 👍");
          })
          .catch((err) => {
            alert(err.message);
          });
        this.setState(this.initialState);
      }
    }
  };

  render() {
    const {
      titleError,
      descriptionError,
      locationError,
      geoRefError,
      projectDateError,
      realizationDateError,
    } = this.state.formErrors;

    return (
      <Layout>
        <div style={{ marginBottom: "30px" }}>
          <Link to="/user">
            <Button variant="link">
              &lt;&lt;Voltar para a página de utilizador
            </Button>
          </Link>
          <h2 style={{ color: "black", textAlign: "center" }}>Novo projeto</h2>
          <form
            onSubmit={this.handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            {/* Title */}
            <div>
              <TextField
                margin="normal"
                style={{ width: "100%" }}
                type="text"
                name="title"
                value={this.state.title}
                onChange={this.handleChange}
                label="Título"
                className={titleError ? "showError" : ""}
                variant="filled"
              />
              {titleError && <div>{titleError}</div>}
            </div>

            {/* Files */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <input
                type="file"
                name="files"
                multiple
                onChange={this.handleChangeFile}
                accept="image/x-png, image/jpeg, video/mp4"
              />
              <Button
                variant="contained"
                style={{
                  backgroundColor: "var(--nav-primary)",
                  margin: "10px 0",
                }}
                onClick={this.handleFileUpdate}
              >
                Upload files
              </Button>
            </div>

            {/* Type */}
            <div>
              <h3>Tipo de projeto:</h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <FormControlLabel
                  label="Architecture"
                  labelPlacement="start"
                  control={
                    <Checkbox
                      type="checkbox"
                      id="architecture"
                      value="architecture"
                      onChange={this.handleOnChangeCheckbox}
                    />
                  }
                />
                <FormControlLabel
                  label="Land"
                  labelPlacement="start"
                  control={
                    <Checkbox
                      type="checkbox"
                      id="land"
                      value="land"
                      onChange={this.handleOnChangeCheckbox}
                    />
                  }
                />

                <FormControlLabel
                  label="Design"
                  labelPlacement="start"
                  control={
                    <Checkbox
                      type="checkbox"
                      id="design"
                      value="design"
                      onChange={this.handleOnChangeCheckbox}
                    />
                  }
                />
              </div>
            </div>

            {/* Description */}
            <div>
              <TextField
                margin="normal"
                style={{ width: "100%" }}
                variant="filled"
                multiline
                rows={5}
                name="description"
                value={this.state.description}
                onChange={this.handleChange}
                label="Descrição"
                className={descriptionError ? "showError" : ""}
              />
              {descriptionError && <div>{descriptionError}</div>}
            </div>

            <div>
              <TextField
                margin="normal"
                style={{ width: "100%" }}
                variant="filled"
                type="text"
                name="location"
                value={this.state.location}
                onChange={this.handleChange}
                label="Localização do projeto"
                className={locationError ? "showError" : ""}
              />
              {locationError && <div>{locationError}</div>}
            </div>

            <div>
              <TextField
                margin="normal"
                style={{ width: "100%" }}
                variant="filled"
                type="url"
                name="geoRef"
                value={this.state.geoRef.value}
                onChange={this.handleChange}
                label="Geo-referência do projeto"
                className={geoRefError ? "showError" : ""}
              />
              {geoRefError && <div>{geoRefError}</div>}
            </div>

            <div>
              <label htmlFor="projectDate">
                Insira a data da criação do projeto
              </label>
              <input
                type="date"
                id="projectDate"
                name="projectDate"
                value={this.state.projectDate.value}
                onChange={this.handleChange}
                className={projectDateError ? "showError" : ""}
              />
              {projectDateError && <div>{projectDateError}</div>}

              <label htmlFor="realizationDate">
                Insira a data da realização do projeto
              </label>
              <input
                type="date"
                id="realizationDate"
                name="realizationDate"
                value={this.state.realizationDate.value}
                onChange={this.handleChange}
                className={realizationDateError ? "showError" : ""}
              />
              {realizationDateError && <div>{realizationDateError}</div>}
            </div>

            <Button
              type="submit"
              style={{
                backgroundColor: "var(--nav-primary)",
                margin: "10px 0",
              }}
              variant="contained"
              size="large"
            >
              Submit
            </Button>
          </form>
        </div>
      </Layout>
    );
  }
}

export default NewProject;
