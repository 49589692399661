import React from "react";
import Logo from "../../Logo/Logo";
import NavigationItems from "../NavigationItems/NavigationItems";

import styles from "./NavigationBar.module.css";

const navigationBar = (props) => (
  <header style={{ paddingTop: "10px" }} className={styles.NavigationBar}>
    <div className={styles.Logo}>
      <Logo />
    </div>

    <nav className={styles.DesktopOnly}>
      <NavigationItems />
    </nav>
  </header>
);

export default navigationBar;
