import { useState, useEffect } from "react";
import { projectFirestore } from "../../firebase/config";

const GetProjectData = (id) => {
  const [project, setProject] = useState([]);
  useEffect(() => {
    const docRef = projectFirestore.collection("Projects").doc(id);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          setProject(doc.data());
        } else {
          console.log("No such document!");
        }
      })
      .catch((err) => {
        console.log("Error getting project: ", err);
      });
  }, [id]);
  return project;
};

export default GetProjectData;
