import React, { Component } from "react";
import Card from "./Card/Card";
import styles from "./Team.module.css";

class Team extends Component {
  state = {
    cards: {
      architects: [
        {
          name: "dino castelo branco",
          role: "arquiteto sénior / engenheiro",
          location: "ponta delgada, azores",
          formation: `universidade de évora <br> universidade de mendrisio`,
        },
        {
          name: "amorim",
          role: "arquiteto",
          location: "amarante",
          formation: ["universidade técnica de lisboa"],
        },
        {
          name: "maria joão brasil",
          role: "arquiteta",
          location: "lagoa",
          formation: ["universidade dos açores e ISCTE-IUL"],
        },
        {
          name: "jorge emanuel pinto",
          role: "arquiteto estagiário",
          location: "loriga",
          formation: ["universidade de coimbra"],
        },
        {
          name: "luis ferreira",
          role: "arquiteto",
          location: "rabo de peixe",
          formation: ["universidade dos açores e ISCTE-IUL"],
        },
      ],

      management: [
        {
          name: "ema",
          role: "administrativa",
          location: "ponta delgada",
          // formation: "Awesome formation",
        },
      ],
    },
  };

  render() {
    return (
      <div className={styles.Team}>
        <div className={styles.Section}>architecture and design</div>

        <ul>
          {this.state.cards.architects.map((architect) => (
            <li key={architect.name}>
              <Card
                name={architect.name}
                image={architect.name}
                role={architect.role}
                location={architect.location}
                formation={architect.formation}
              />
            </li>
          ))}
        </ul>
        <div className={styles.Section}>management</div>
        <ul>
          {this.state.cards.management.map((worker) => (
            <li key={worker.name}>
              <Card
                name={worker.name}
                image={worker.image}
                role={worker.role}
                location={worker.location}
                formation={worker.formation}
                hidden
              />
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Team;
