import React from "react";
import Layout from "../../../../hoc/Layout/Layout";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const UpdatePost = () => {
  return (
    <Layout>
      <div style={{ marginBottom: "30px" }}>
        <Link to="/delete-post">
          <Button variant="link">
            &lt;&lt;Voltar para a página de publicações
          </Button>
        </Link>
        <h2 style={{ color: "black", textAlign: "center" }}>Editar publicação</h2>
      </div>
    </Layout>
  );
};

export default UpdatePost;