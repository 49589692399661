import React from "react";
import styles from "./ContactItem.module.css";

const contactItem = (props) => (
  <li className={styles.ContactItem}>
    <a href={props.link} target="_blank" rel="noreferrer">
      {props.label}
    </a>
  </li>
);

export default contactItem;
