import { createAvatar } from "@dicebear/avatars";
import { identicon } from "@dicebear/collection";
import React from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMapMarkerAlt,
  faUniversity,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./Card.module.css";

library.add(faMapMarkerAlt, faUniversity);

const card = (props) => {
  const formation = props.formation;
  let svg = createAvatar(identicon, {
    seed: props.name,
    size: "120px",
    radius: "50",
    backgroundColor: "#ffffff",
  });

  let container = null;
  if (!props.hidden) {
    container = (
      <div className={styles.Card}>
        <div
          className={styles.Image}
          dangerouslySetInnerHTML={{ __html: svg }}
        />

        <div className={styles.CardContainer}>
          <h2>{props.role}</h2>
          <h4>{props.name}</h4>
          <p>
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              style={{ width: "1em" }}
              className={styles.Icon}
            />{" "}
            {props.location}
          </p>
          <p>
            <FontAwesomeIcon icon={faUniversity} className={styles.Icon} />{" "}
            <span dangerouslySetInnerHTML={{ __html: formation }} />
          </p>
        </div>
      </div>
    );
  } else {
    container = (
      <div className={styles.Card}>
        <div
          className={styles.Image}
          dangerouslySetInnerHTML={{ __html: svg }}
        />
        <div className={styles.CardContainer}>
          <h2>{props.role}</h2>
          <h4>{props.name}</h4>
          <p>
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              style={{ width: "1em" }}
              className={styles.Icon}
            />{" "}
            {props.location}
          </p>
        </div>
      </div>
    );
  }
  return container;
};

export default card;
