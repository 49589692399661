import React, { Component } from "react";

import Auxiliary from "../../hoc/Auxiliary";
import NavigationBar from "../../components/Navigation/NavigationBar/NavigationBar";
import style from "./Layout.module.css";

class Layout extends Component {
  render() {
    return (
      <Auxiliary>
        <NavigationBar />
        <main className={style.Content}>{this.props.children}</main>
      </Auxiliary>
    );
  }
}

export default Layout;
