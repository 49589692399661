import React from "react";

import styles from "./Posts.module.css";
import { projectFirestore } from "../../firebase/config";
import { useCollectionData } from "react-firebase-hooks/firestore";

const Post = () => {
  const docRef = projectFirestore.collection("News");
  const query = docRef.orderBy("createdAt", "desc");
  const [posts] = useCollectionData(query, { idField: "id" });

  return (
    <div>
      {posts &&
        posts.map((post) => {
          const data = post.createdAt.toDate();
          const day = data.getUTCDate();
          const month = data.getUTCMonth();
          const year = data.getUTCFullYear();
          return (
            <div key={post.id} className={styles.Post}>
              <div className={styles.Image}>
                <img src={post.url} alt={post.title} />
              </div>
              <div className={styles.Text}>
                <p>
                  <b>
                    {day}/{month}/{year}
                  </b>{" "}
                  {post.description}
                </p>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Post;
