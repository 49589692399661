import React, { Component } from "react";
import Layout from "../../hoc/Layout/Layout";
import Page404 from "../../components/Page404/Page404";

class notFound extends Component {
  render() {
    return (
      <Layout>
        <Page404 />
      </Layout>
    );
  }
}

export default notFound;
