import React from "react";
import styles from "./Project.module.css";
import GetProjectData from "../hooks/GetProjectData";
import { useParams } from "react-router-dom";

import Layout from "../../hoc/Layout/Layout";

const Project = () => {
  const { id } = useParams();
  const project = GetProjectData(id);

  return (
    <Layout>
      <div className={styles.Project}>
        <div className={styles.ImageContainer}>
          <ul style={{ padding: "0", margin: "0" }}>
            {project.url &&
              project.url.map((image) => (
                <li className={styles.ImageList} key={image}>
                  <img src={image} alt={image} />
                </li>
              ))}
          </ul>
        </div>
        <div className={styles.Text}>
          <p
            style={{
              fontSize: "1.25rem",
              fontWeight: "600",
              marginBottom: "20px",
            }}
          >
            {project.title}
          </p>
          <a href={project.geoRef} target="_blank" rel="noreferrer">
            {project.location}
          </a>
          <div style={{ margin: "25px 0" }}>
            {project.type &&
              project.type.slice(1).map((type) => <p key={type}>{type}</p>)}
          </div>
          <p>{project.description}</p>
          <p style={{ marginTop: "25px" }}>
            project {project.projectDate}, realization {project.realizationDate}
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Project;
