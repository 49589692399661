import { Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import React from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";
import { projectFirestore } from "../../../../firebase/config";
import Layout from "../../../../hoc/Layout/Layout";

const DeleteCover = () => {
  const docRef = projectFirestore.collection("Gallery");
  const query = docRef.orderBy("createdAt", "desc");
  const [covers] = useCollectionData(query, { idField: "id" });

  // Modal operations
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Layout>
      <Link to="/user">
        <Button variant="link">
          &lt;&lt;Voltar para a página de utilizador
        </Button>
      </Link>
      <h2 style={{ color: "black", textAlign: "center" }}>Eliminar Postal</h2>
      <ul style={{ listStyle: "none", padding: "0", margin: "0" }}>
        {covers &&
          covers.map((cover) => {
            return (
              <li
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  alignItems: "center",
                  borderBottom: "1px solid",
                }}
              >
                <h3>{cover.name}</h3>
                <img
                  src={cover.url}
                  alt={cover.name}
                  style={{ width: "80%" }}
                />
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "var(--nav-primary)",
                    margin: "10px 0",
                  }}
                  onClick={handleOpen}
                  // onClick={() => handleDelete(cover.id)}
                >
                  Apagar {cover.name}
                </Button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      margin: "4% 0",
                      maxHeight: "620px",
                      boxShadow: "0px 0px 6px rgb(0 0 0 / 15%)",
                      backgroundColor: "white",
                      width: "40%",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h4>
                        Tem a certeza que quer apagar o postal {cover.name}?
                      </h4>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <Button>Sim</Button>
                      <Button>Não</Button>
                    </div>
                  </Box>
                </Modal>
              </li>
            );
          })}
      </ul>
    </Layout>
  );
};

export default DeleteCover;
