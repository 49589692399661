import React from "react";

import styles from "./Page404.module.css";

const page404 = () => (
  <div className={styles.Page404}>
    <h1>404</h1>
    <h2>page not found.</h2>
    <p>looks like the page your looking for isn't available.</p>
    <p>sorry about that</p>
  </div>
);

export default page404;