import React, { Component } from "react";
import {
  projectStorage,
  projectFirestore,
  timestamp,
} from "../../../../firebase/config";
import Layout from "../../../../hoc/Layout/Layout";

import { Button, TextField } from "@mui/material";
import { Link } from "react-router-dom";

class NewPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      url: "",
      file: null,
      createdAt: timestamp(),
      formErrors: {},
    };

    this.initialState = this.state;
  }

  handleFormValidation = () => {
    const { title, file, description } = this.state;
    let formErrors = {};
    let formIsValid = true;

    // Name
    if (!title) {
      formIsValid = false;
      formErrors["titleError"] = "Tem de inserir um título para a publicação";
    }

    // Description
    if (!description) {
      formIsValid = false;
      formErrors["descriptionError"] =
        "Tem de inserir uma descrição para a publicação";
    }
    // File
    if (!file) {
      formIsValid = false;
      formErrors["fileError"] = "Tem de inserir uma foto para a publicação";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  handleFileChange = (e) => {
    let file = e.target;
    let selected = file.files[0];
    this.setState({ file: selected });
  };

  handleFileUpdate = () => {
    if (this.state.file) {
      const storageRef = projectStorage.ref();
      let fileRef = storageRef.child(`News/${this.state.title}`);

      let uploadTask = fileRef.put(this.state.file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.setState({ url: downloadURL });
          });
        }
      );
    }
  };

  // onChange para todos os inputs de texto e data
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const values = {
      title: this.state.title,
      description: this.state.description,
      url: this.state.url,
      createdAt: this.state.createdAt,
    };
    if (this.handleFormValidation()) {
      projectFirestore
        .collection("News")
        .add(values)
        .then(() => {
          alert("Successfully created News 👍");
        })
        .catch((err) => {
          alert(err.message);
        });
      this.setState(this.initialState);
    }
  };

  render() {
    const { titleError, descriptionError, fileError } = this.state.formErrors;
    return (
      <Layout>
        <div style={{ marginBottom: "30px" }}>
          <Link to="/user">
            <Button variant="link">
              &lt;&lt;Voltar para a página de utilizador
            </Button>
          </Link>
          <h2 style={{ color: "black", textAlign: "center" }}>
            Nova publicação
          </h2>
          <form
            onSubmit={this.handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            {/* Title */}
            <div>
              <TextField
                margin="normal"
                style={{ width: "100%" }}
                type="text"
                name="title"
                value={this.state.title}
                onChange={this.handleChange}
                label="Título"
                className={titleError ? "showError" : ""}
                variant="filled"
              />
              {titleError && <div>{titleError}</div>}
            </div>
            {/* Files */}
            <div>
              <input
                type="file"
                name="files"
                onChange={this.handleFileChange}
                accept="image/x-png, image/jpeg"
              />
              {fileError && <div>{fileError}</div>}
            </div>
            <div>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "var(--nav-primary)",
                  margin: "10px 0",
                }}
                onClick={this.handleFileUpdate}
              >
                Upload
              </Button>
            </div>
            <div>
              <div>
                <TextField
                  margin="normal"
                  style={{ width: "100%" }}
                  variant="filled"
                  multiline
                  rows={5}
                  name="description"
                  value={this.state.description}
                  onChange={this.handleChange}
                  label="Insira a descrição para a publicação"
                  className={descriptionError ? "showError" : ""}
                />
                {descriptionError && <div>{descriptionError}</div>}
              </div>
            </div>
            <Button
              type="submit"
              style={{
                backgroundColor: "var(--nav-primary)",
                margin: "10px 0",
              }}
              variant="contained"
              size="large"
            >
              Submit
            </Button>
          </form>
        </div>
      </Layout>
    );
  }
}

export default NewPost;
