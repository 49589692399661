import React from "react";
import { projectFirestore } from "../../../../firebase/config";
import { useCollectionData } from "react-firebase-hooks/firestore";
import Layout from "../../../../hoc/Layout/Layout";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const DeletePost = () => {
  const docRef = projectFirestore.collection("News");
  const query = docRef.orderBy("createdAt", "desc");
  const [posts] = useCollectionData(query, { idField: "id" });

  const handleDelete = (id) => {
    const docRef = projectFirestore.collection("News").doc(id);
    docRef.delete();
    console.log("Deleted post data from id: " + id);
  };
  return (
    <Layout>
      <Link to="/user">
        <Button variant="link">
          &lt;&lt;Voltar para a página de utilizador
        </Button>
      </Link>
      <h2 style={{ color: "black", textAlign: "center" }}>
        Eliminar Publicação
      </h2>
      <ul style={{ listStyle: "none", padding: "0", margin: "0" }}>
        {posts &&
          posts.map((post) => {
            const data = post.createdAt.toDate();
            const day = data.getUTCDate();
            const month = data.getUTCMonth();
            const year = data.getUTCFullYear();
            return (
              <li
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderBottom: "1px solid",
                  marginBottom: "30px",
                }}
                key={post.id}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={post.url}
                    alt={post.title}
                    style={{ width: "75%", justifyContent: "center" }}
                  />
                  <p style={{ padding: "0 15%", margin: "20px 0" }}>
                    <b>
                      {day}/{month}/{year}
                    </b>{" "}
                    {post.description}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <Link to="/edit-post" style={{
                      width: "45%",
                    }}>
                    <Button
                      variant="contained"
                      size="large"
                      style={{
                        backgroundColor: "var(--nav-primary)",
                        width: "100%",
                      }}
                    >
                      Editar Post
                    </Button>
                  </Link>
                  <Button
                    variant="contained"
                    size="large"
                    style={{
                      backgroundColor: "var(--nav-primary)",
                      width: "45%",
                    }}
                    onClick={() => handleDelete(post.id)}
                  >
                    Apagar Post
                  </Button>
                </div>
                <Link to="/user">
                  <Button variant="link">
                    &lt;&lt;Voltar para a página de utilizador
                  </Button>
                </Link>
              </li>
            );
          })}
      </ul>
    </Layout>
  );
};

export default DeletePost;
