import { firebase } from "@firebase/app";
import "firebase/storage";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: "AIzaSyA2gmzFYmJiSiAgp9KwNNLAakr001NXel4",
  authDomain: "understudio-6927d.firebaseapp.com",
  databaseURL:
    "https://understudio-6927d-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "understudio-6927d",
  storageBucket: "understudio-6927d.appspot.com",
  messagingSenderId: "370774283544",
  appId: "1:370774283544:web:cf1f41d7304dc7aa7fba6d",
  measurementId: "G-JSDTVEXP78",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const projectAuth = firebase.auth();
const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectAuth, projectStorage, projectFirestore, timestamp };
