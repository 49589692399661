import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./NavigationItem.module.css";

const NavigationItem = (props) => {
  const dropList = props.dropList;
  //IF LINK IS DIFERENT THAN THE NAME:
  // const dropdownLink = props.dropdownLink;
  const prefix = [
    "http://localhost:3000",
    "http://192.168.1.92:3000",
    "https://understudio.com/projects",
  ];
  const listUrl = [
    "/projects",
    "/projects/architecture",
    "/projects/land",
    "/projects/design",
    "/projects/list",
  ];
  let fullUrl = [];
  for (let i = 0; i < prefix.length; i++) {
    for (let j = 0; j < listUrl.length; j++) {
      fullUrl.push(prefix[i] + listUrl[j]);
    }
  }
  let url = window.location.href;

  return (
    <li className={styles.NavigationItem}>
      <NavLink to={props.link} activeClassName={styles.active}>
        {props.name}
      </NavLink>
      {fullUrl.includes(url) && (
        <div>
          {dropList && (
            <div className={styles.DropdownItems}>
              <ul className={styles.DropdownItem}>
                {dropList.map((drop) => {
                  return (
                    <li key={drop.id} className={styles.Visible}>
                      <NavLink
                        exact
                        to={`${props.link}/${drop.value}`}
                        activeClassName={styles.active}
                      >
                        {drop.value}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      )}
    </li>
  );
};

export default NavigationItem;
