import React, { Component } from "react";

import Projects from "./Projects";

class Portfolio extends Component {
  render() {
    let portfolio = null;
    switch (this.props.type) {
      case "all":
        portfolio = <Projects/>;
        break;
      case "architecture":
        portfolio = <Projects condition="architecture" />;
        break;
      case "land":
        portfolio = <Projects condition="land" />;
        break;
      case "design":
        portfolio = <Projects condition="design" />;
        break;
      case "list":
        portfolio = <Projects condition="list" />;
        break;
      default:
        portfolio = null;
    }
    return portfolio;
  }
}

export default Portfolio;
