import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Home from "./containers/Home/Home";
import Cover from "./components/Slideshow/Cover/Cover";
import Portfolio from "./containers/Portfolio/Portfolio";
import Project from "./components/Project/Project";
import News from "./containers/News/News";
import Office from "./containers/Office/Office";

import Architecture from "./containers/Portfolio/Architecture/Architecture";
import Land from "./containers/Portfolio/Land/Land";
import Design from "./containers/Portfolio/Design/Design";
import List from "./containers/Portfolio/List/List";

import Contact from "./containers/Office/Contact/Contact";
import Team from "./containers/Office/Team/Team";

import NotFound from "./containers/NotFound/NotFound";

import Authentication from "./components/Authentication/Authentication";

import NewProject from "./components/CRUD/Create/NewProject/NewProject";
import NewCover from "./components/CRUD/Create/NewCover/NewCover";
import NewPost from "./components/CRUD/Create/NewPost/NewPost";
import UpdatePost from "./components/CRUD/Update/UpdatePost/UpdatePost";
import UpdateProject from "./components/CRUD/Update/UpdateProject/UpdateProject";
import DeleteProject from "./components/CRUD/Delete/DeleteProject/DeleteProject";
import DeleteCover from "./components/CRUD/Delete/DeleteCover/DeleteCover";
import DeletePost from "./components/CRUD/Delete/DeletePost/DeletePost";

class App extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/">
          <Redirect to="/home"></Redirect>
        </Route>
        <Route path="/home" exact>
          <Home />
        </Route>
        <Route path="/home/:id">
          <Cover />
        </Route>
        <Route path="/projects" exact component={Portfolio} />
        <Route path="/news" exact component={News} />
        <Route path="/office" exact component={Office} />
        <Route path="/projects/architecture" exact component={Architecture} />
        <Route path="/projects/land" exact component={Land} />
        <Route path="/projects/design" exact component={Design} />
        <Route path="/projects/list" exact component={List} />
        <Route path="/office" exact>
          <Redirect to="/office/contact" />
        </Route>
        <Route path="/team" exact component={Team} />
        <Route path="/office/contact" exact component={Contact} />
        <Route path="/project/:id">
          <Project />
        </Route>

        <Route path="/user">
          <Authentication />
        </Route>

        <Route path="/new-project" exact component={NewProject} />
        <Route path="/new-cover" exact component={NewCover} />
        <Route path="/new-post" exact component={NewPost} />
        <Route path="/edit-post" exact component={UpdatePost} />
        <Route path="/edit-project" exact component={UpdateProject} />
        <Route path="/delete-project" exact component={DeleteProject} />
        <Route path="/delete-cover" exact component={DeleteCover} />
        <Route path="/delete-post" exact component={DeletePost} />

        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default App;
