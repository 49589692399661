import { Button } from "@mui/material";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { projectFirestore, timestamp } from "../../../../firebase/config";
import Layout from "../../../../hoc/Layout/Layout";

class UpdateProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      files: [],
      fileInfo: [],
      url: [],
      type: ["list"],
      description: "",
      location: "",
      geoRef: "",
      projectDate: "",
      realizationDate: "",
      createdAt: timestamp(),
      formErrors: {},
    };

    this.initialState = this.state;
  }
  handleFormValidation = () => {
    const {
      title,
      description,
      location,
      geoRef,
      projectDate,
      realizationDate,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    // Title
    if (!title) {
      formIsValid = false;
      formErrors["titleError"] = "Tem que inserir um título";
    }

    // Description
    if (!description) {
      formIsValid = false;
      formErrors["descriptionError"] = "Tem que inserir uma descrição";
    }

    // Location
    if (!location) {
      formIsValid = false;
      formErrors["locationError"] = "Tem que inserir uma localização";
    }

    // GeoRef
    if (!geoRef) {
      formIsValid = false;
      formErrors["geoRefError"] = "Tem que inserir um URL com a geo-referência";
    }
    // Project Date
    if (!projectDate) {
      formIsValid = false;
      formErrors["projectDateError"] = "Tem que inserir uma data";
    }

    // Realization Date
    if (!realizationDate) {
      formIsValid = false;
      formErrors["realizationDateError"] = "Tem que inserir uma data";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  // onChange para todos os inputs de texto e data
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  // submit do projeto
  handleSubmit = (e) => {
    const values = {
      title: this.state.title,
      files: this.state.files,
      url: this.state.url,
      type: this.state.type,
      description: this.state.description,
      location: this.state.location,
      geoRef: this.state.geoRef,
      projectDate: this.state.projectDate,
      realizationDate: this.state.realizationDate,
      createdAt: this.state.createdAt,
    };
    e.preventDefault();
    if (this.handleFormValidation()) {
      if (this.state.files) {
        projectFirestore
          .collection("Projects")
          .add(values)
          .then(() => {
            alert("Successfully created a new project 👍");
          })
          .catch((err) => {
            alert(err.message);
          });
        this.setState(this.initialState);
      }
    }
  };

  render() {
    return (
      <Layout>
        <div style={{ marginBottom: "30px" }}>
          <Link to="/delete-project">
            <Button variant="link">
              &lt;&lt;Voltar para a página de projetos
            </Button>
          </Link>
          <h2 style={{ color: "black", textAlign: "center" }}>
            Editar Projecto
          </h2>
        </div>
      </Layout>
    );
  }
}

export default UpdateProject;
