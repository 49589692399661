import React from "react";

import underLogo from "../../assets/Logo.png";
import NavigationItem from "../Navigation/NavigationItems/NavigationItem/NavigationItem";
import styles from "./Logo.module.css";

const logo = (props) => (
  <div className={styles.Logo} style={{ height: props.height }}>
    <NavigationItem
      link="/"
      name={<img src={underLogo} alt="Understudio" />}
      exact
    />
  </div>
);

export default logo;
