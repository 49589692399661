import React, { Component } from "react";
import {
  projectStorage,
  projectFirestore,
  timestamp,
} from "../../../../firebase/config";
import Layout from "../../../../hoc/Layout/Layout";

import { Button, TextField } from "@mui/material";
import { Link } from "react-router-dom";

class NewCover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      url: "",
      file: null,
      createdAt: timestamp(),
      formErrors: {},
    };

    this.initialState = this.state;
  }

  handleFormValidation = () => {
    const { name, file } = this.state;
    let formErrors = {};
    let formIsValid = true;

    // Name
    if (!name) {
      formIsValid = false;
      formErrors["nameError"] = "Tem de inserir um titulo para a capa";
    }
    // File
    if (!file) {
      formIsValid = false;
      formErrors["fileError"] = "Tem de inserir uma capa para o website";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  handleFileChange = (e) => {
    let file = e.target;
    let selected = file.files[0];
    this.setState({ file: selected });
  };

  handleFileUpdate = () => {
    if (this.state.file) {
      const storageRef = projectStorage.ref();
      let fileRef = storageRef.child(`Gallery/${this.state.name}`);

      let uploadTask = fileRef.put(this.state.file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.setState({ url: downloadURL });
          });
        }
      );
    }
  };

  // onChange para todos os inputs de texto e data
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const values = {
      name: this.state.name,
      url: this.state.url,
      createdAt: this.state.createdAt,
    };

    if (this.handleFormValidation()) {
      projectFirestore
        .collection("Gallery")
        .add(values)
        .then(() => {
          alert("Successfully created a new Cover 👍");
        })
        .catch((err) => {
          alert(err.message);
        });
      this.setState(this.initialState);
    }
  };

  render() {
    const { nameError, fileError } = this.state.formErrors;
    return (
      <Layout>
        <div style={{ marginBottom: "30px" }}>
          <Link to="/user">
            <Button variant="link">
              &lt;&lt;Voltar para a página de utilizador
            </Button>
          </Link>
          <h2 style={{ color: "black", textAlign: "center" }}>Novo postal</h2>
          <form
            onSubmit={this.handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            {/* Name */}
            <div>
              <TextField
                margin="normal"
                style={{ width: "100%" }}
                type="text"
                name="name"
                value={this.state.title}
                onChange={this.handleChange}
                label="Título do postal"
                className={nameError ? "showError" : ""}
                variant="filled"
              />
              {nameError && <div>{nameError}</div>}
            </div>
            {/* Files */}
            <div>
              <input
                type="file"
                name="files"
                onChange={this.handleFileChange}
                accept="image/x-png, image/jpeg"
              />
              {fileError && <div>{fileError}</div>}
            </div>
            <div>
              <Button
                type="button"
                variant="contained"
                style={{
                  backgroundColor: "var(--nav-primary)",
                  margin: "10px 0",
                }}
                onClick={this.handleFileUpdate}
              >
                Upload files
              </Button>
            </div>

            <Button
              type="submit"
              style={{
                backgroundColor: "var(--nav-primary)",
                margin: "10px 0",
              }}
              variant="contained"
              size="large"
            >
              Submit
            </Button>
          </form>
        </div>
      </Layout>
    );
  }
}

export default NewCover;
