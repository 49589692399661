import React, { Component } from "react";
import Team from "./Team/Team";
import Contact from "./Contact/Contact";

class OfficeInformation extends Component {
  render() {
    let office = null;
    switch (this.props.type) {
      case "office":
        office = <div><Contact /></div>;
        break;
      case "contact":
        office = (
          <div>
            <Contact />
          </div>
        );
        break;
      case "team":
        office = (
          <div>
            <Team />
          </div>
        );
        break;
      default:
        office = null;
    }
    return office;
  }
}

export default OfficeInformation;
