import React from "react";
import { Link, NavLink } from "react-router-dom";
import styles from "./Projects.module.css";
import { projectFirestore } from "../../firebase/config";
import { useCollectionData } from "react-firebase-hooks/firestore";

const Projects = (props) => {
  const docRef = projectFirestore.collection("Projects");
  const query = docRef.orderBy("createdAt", "desc");
  const [projects] = useCollectionData(query, { idField: "id" });
  let condition = props.condition;

  return (
    <div>
      <ul className={styles.ProjectGrid}>
        {projects &&
          projects.map((project) => {
            if (condition === "list") {
              if (!project.url[0]) {
                return (
                  <li className={styles.ProjectTitle} key={project.id}>
                    <div>{project.title}</div>
                  </li>
                );
              } else {
                return (
                  <li className={styles.ProjectTitle} key={project.id}>
                    <NavLink
                      className={styles.Link}
                      to={"/project/" + project.id}
                    >
                      <div>{project.title}</div>
                    </NavLink>
                  </li>
                );
              }
            } else if (
              (!condition || project.type.includes(condition)) &&
              project.url[0]
            ) {
              return (
                <li className={styles.Projects} key={project.id}>
                  <Link to={"/project/" + project.id}>
                    <img
                      className={styles.Image}
                      src={project.url[0]}
                      alt={project.title}
                    />
                    <div className={styles.Overlay}>
                      <div className={styles.Text}>{project.title}</div>
                    </div>
                  </Link>
                </li>
              );
            }
            return null;
          })}
      </ul>
    </div>
  );
};

export default Projects;
