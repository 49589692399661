import React from "react";
import { projectFirestore } from "../../../../firebase/config";
import { useCollectionData } from "react-firebase-hooks/firestore";
import Layout from "../../../../hoc/Layout/Layout";
import { Link } from "react-router-dom";
import { Button, ImageList, ImageListItem } from "@mui/material";

const DeleteProject = () => {
  const docRef = projectFirestore.collection("Projects");
  const query = docRef.orderBy("createdAt", "desc");
  const [projects] = useCollectionData(query, { idField: "id" });

  const handleDelete = (id) => {
    const docRef = projectFirestore.collection("Projects").doc(id);
    docRef.delete();
    console.log("Deleted project data from id: " + id);
  };
  return (
    <Layout>
      <Link to="/user">
        <Button variant="link">
          &lt;&lt;Voltar para a página de utilizador
        </Button>
      </Link>
      <h2 style={{ color: "black", textAlign: "center" }}>Eliminar Projeto</h2>
      <ul style={{ listStyle: "none", padding: "0", margin: "0" }}>
        {projects &&
          projects.map((project) => {
            const data = project.createdAt.toDate();
            const day = data.getUTCDate();
            const month = data.getUTCMonth();
            const year = data.getUTCFullYear();
            return (
              <li
                style={{
                  borderBottom: "1px solid",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <h3 style={{ fontSize: "1.8rem", marginBottom: "5px" }}>
                    {project.title}
                  </h3>
                  <p style={{ fontSize: "1.2rem" }}>
                    Descrição: {project.description}
                  </p>
                  <div style={{ fontSize: "1.2rem" }}>
                    Tipo:
                    {project.type.slice(1).map((type) => (
                      <span key={type}> {type}</span>
                    ))}
                    <p style={{ fontSize: "1.2rem" }}>
                      Data de publicação do projeto: {day}/{month}/{year}
                    </p>
                  </div>
                </div>
                <ImageList
                  cols={3}
                  gap={5}
                  style={{
                    margin: "4% 0",
                    maxHeight: "620px",
                    boxShadow: "0px 0px 6px rgb(0 0 0 / 15%)",
                  }}
                >
                  {project.url &&
                    project.url.map((image) => (
                      <ImageListItem key={image}>
                        <img src={image} alt={image} />
                      </ImageListItem>
                    ))}
                </ImageList>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginBottom: "30px",
                  }}
                >
                  <Link to="/edit-project" style={{
                      width: "45%",
                    }}>
                  <Button
                    variant="contained"
                    size="large"
                    style={{
                      backgroundColor: "var(--nav-primary)",
                      width: "100%",
                    }}
                  >
                    Editar {project.title}
                  </Button>
                  </Link>
                  <Button
                    variant="contained"
                    size="large"
                    style={{
                      backgroundColor: "var(--nav-primary)",
                      width: "45%",
                    }}
                    onClick={() => handleDelete(project.id)}
                  >
                    Apagar {project.title}
                  </Button>
                </div>
                <Link to="/user">
                  <Button variant="link">
                    &lt;&lt;Voltar para a página de utilizador
                  </Button>
                </Link>
              </li>
            );
          })}
      </ul>
    </Layout>
  );
};

export default DeleteProject;
